import { FilterKey } from 'app/analytics/analytics-widget-filter/analytics-widget-filter';
import { ApplicationSummaryDoc } from 'app/applications/interfaces/documents/app-summary-doc';
import { FilterValue } from 'app/components/filter-constellation/interfaces/filter-value';
import { Campus } from 'app/entities/campus';
import { ListItem } from 'app/entities/list-item';
import { AppEnquiry, Enquiries } from 'app/entities/local/enquiries';
import { Legend } from 'app/entities/local/legend';
import { ChartRawData } from 'app/entities/local/pie-chart-col-data';
import { PieSeries } from 'app/entities/local/pie-series';
import { ZingData, ZingOptionsStyleCustomTooltipStyle, ZingSeries } from 'app/entities/local/zing-chart';
import { Student } from 'app/entities/student';
import { YearLevel } from 'app/entities/year-level';
import { ChartEntity } from 'app/state/chart';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CustomZingChartAngularComponent } from 'zingchart';

import { ChartInitData } from './chart-init-data';
import { Constants as TempConstants } from './constants';
import { ApplicationStatus, ChartType } from './enums';
import { ChartSection, EventOrPersonalTour } from './interfaces';
import { Keys } from './keys';
import { CommaSeparatedNumberPipe } from './pipes/comma-separated-number.pipe';
import { T } from './t';
import { Colors, Utils } from './utils';

export class Constants {
    static readonly GRID_CHART_LEFT_WHITE_SPACE = 76;
    static readonly GRID_CHART_RIGHT_WHITE_SPACE = 90;
    static readonly DEFAULT_HEIGHT = 266;
    static readonly HEIGHT_WITH_RANGE_SELECTOR = 366;
    static readonly HEIGHT_WITH_GRID_CHART = 320;
    static readonly HORIZONTAL_BAR_CHART_DEFAULT_LABEL = 6;

    static readonly COMPARE_STARTING_YEAR_COLUMN_NAME = 'Comparison <br> date';
    static readonly FILTER_ADDITIONAL_INFO_TEXT =
        'This setting allows you to see how many enquiries you had for other starting years when you were this far removed from the selected starting year.';
}

const classNamesByText = {
    EY3: 'series-ey3',

    EY4: 'series-ey4',
    'Early Years / Kinder': 'series-ey4',
    'Pre-K': 'series-ey4',
    'Pre-Kindergarten': 'series-ey4',

    Prep: 'series-prep',
    'Prep, Foundation, Reception': 'series-prep',
    Kindergarten: 'series-prep',

    'Year 1': 'series-year-one',
    '1st Grade': 'series-year-one',

    'Year 2': 'series-year-two',
    '2nd Grade': 'series-year-two',

    'Year 3': 'series-year-three',
    '3rd Grade': 'series-year-three',

    'Year 4': 'series-year-four',
    '4th Grade': 'series-year-four',

    'Year 5': 'series-year-five',
    '5th Grade': 'series-year-five',

    'Year 6': 'series-year-six',
    '6th Grade': 'series-year-six',

    'Year 7': 'series-year-seven',
    '7th Grade': 'series-year-seven',

    'Year 8': 'series-year-eight',
    '8th Grade': 'series-year-eight',

    'Year 9': 'series-year-nine',
    '9th Grade': 'series-year-nine',

    'Year 10': 'series-year-ten',
    '10th Grade': 'series-year-ten',

    'Year 11': 'series-year-eleven',
    '11th Grade': 'series-year-eleven',

    'Year 12': 'series-year-twelve',
    '12th Grade': 'series-year-twelve',

    // Application status
    Interest: 'series-interest',
    Applicant: 'series-applicant',
    Enroled: 'series-enrolled',
    Enrolled: 'series-enrolled',
    Declined: 'series-declined',

    // Common legends
    Contacts: 'series-contact',
    Other: 'series-other',
    'Other attendees': 'series-unknown',
    Unknown: 'series-unknown',
    Yes: 'series-success',
    No: 'series-danger',

    // School status legends
    Feeder: 'series-primary',
    'Non-Feeder': 'series-success',

    // Gender legends
    Male: 'series-male',
    Female: 'series-female',
};

const numberedSeriesClassNames = [
    'series-1',
    'series-2',
    'series-3',
    'series-4',
    'series-5',
    'series-6',
    'series-7',
    'series-8',
    'series-9',
    'series-10',
    'series-11',
    'series-12',
    'series-13',
    'series-14',
    'series-15',
    'series-16',
    'series-17',
    'series-18',
];

export function getClassNames(listItemNames: string[]): string[] {
    const namedSeries = listItemNames.map(text => classNamesByText[text]);
    const generator = new SeriesStyleGenerator({ usedClasses: namedSeries });
    const classNames = namedSeries.map(name => name ?? generator.getClassName());
    return classNames;
}

export function getColor(seriesName): string {
    return Utils.getCssProperty(`${seriesName}-color`);
}

export function getBackgroundAlpha(seriesName): number {
    return parseFloat(Utils.getCssProperty(`${seriesName}-bg-alpha`));
}

export interface Options {
    classNames?: string[];
    usedClasses?: string[];
}

export interface DeclinedStudents {
    intakeYear: number[];
    students: Student[];
    yearLevels: YearLevel[];
    campuses: Campus[];
    intakeYears: ListItem[];
}

export class SeriesStyleGenerator {
    private palette: string[];
    private unusedClasses: string[];

    constructor({ classNames = numberedSeriesClassNames, usedClasses = [] }: Options = {}) {
        this.palette = _.uniqBy(classNames, name => getColor(name));
        this.replenishColors();
        this.remove(usedClasses);
    }

    public remove(nameOrNames: string | string[]): void {
        let classNames: string[];
        if (typeof nameOrNames === 'string') {
            classNames = [nameOrNames];
        } else {
            classNames = nameOrNames;
        }
        const colors = classNames.filter(name => name).map(name => getColor(name));
        _.remove(this.unusedClasses, name => _.includes(colors, getColor(name)));
    }

    public getClassName(): string {
        if (!this.unusedClasses.length) {
            this.replenishColors();
        }
        return this.unusedClasses.shift();
    }

    private replenishColors(): void {
        this.unusedClasses = this.palette.slice();
    }
}

export const enquiriesEBMChartMetaDataLegends: Legend[] = [
    {
        id: 1,
        name: 'Events',
        icon: 'arrow_drop_down',
        isSelected: false,
        className: 'increase-icon-size',
    },
    {
        id: 2,
        name: 'Appointments',
        icon: 'circle',
        isSelected: false,
    },
];

export const lastThreeYearAverage = 3;
export const lastFiveYearAverage = 5;
const precision = 2;
const percentageMultiplier = 100;

export const enquiriesYOYChartMetaDataLegends: Legend[] = [
    {
        id: lastThreeYearAverage,
        name: '3 YEAR AVERAGE',
        icon: 'horizontal_rule',
        isSelected: false,
        rollover: 'Last 3 Year Average',
    },
    {
        id: lastFiveYearAverage,
        name: '5 YEAR AVERAGE',
        icon: 'more_horiz',
        isSelected: false,
        rollover: 'Last 5 Year Average',
    },
];

export class ChartMetaData {
    legends: Array<Legend>;
    series?: number[][];
    shapes?: Array<EventOrPersonalTour[][]>;
}

export function prepareMixSeries(series: number[], legend: Legend): ZingSeries {
    let mixSeries = ChartInitData.getMixedLineChartInitData();
    mixSeries.values = series;
    if (legend.id === lastFiveYearAverage) {
        mixSeries.lineStyle = 'dashed';
    }
    mixSeries.text = legend.rollover;
    return mixSeries;
}

/**
 * Calculate a scale Y display range so show total number
 * can be displayed properly & also avoid range to be displayed
 * in decimal.
 * @param maxValue
 * @returns
 */
export function getScaleYValues(maxValue: number) {
    const range = 10;

    if (maxValue < range) {
        return `0:${maxValue + 1}:1`;
    }

    const deducation = 1;
    const multipler = 2;

    const pow10Log = Math.pow(10, Math.ceil(Math.log10(maxValue / range) - deducation));
    const roundStep = Math.ceil(maxValue / range / pow10Log) * pow10Log;
    const newMaxValue = maxValue - (maxValue % roundStep) + roundStep * multipler;
    return `0:${newMaxValue}:${roundStep}`;
}

export function getStackChartMaxValue(series: ZingSeries[], scaleXLabel: string[]): number {
    return _.max(_.map(scaleXLabel, (label, index) => _.sum(_.map(series, s => s.values[index]))));
}

export function getChartTotal(series: ZingSeries[]): number {
    return _.sum(series.map(item => _.sum(item.values)));
}

export interface LineAndAreaChartCommonProperty {
    type: string;
    plotRuleText: string;
    plotRulerule: string;
    stacked: boolean;
}

export const lineChartCommonProperty: LineAndAreaChartCommonProperty = {
    type: 'line',
    plotRuleText: '%v',
    plotRulerule: '%v <= 0',
    stacked: false,
};

export const lineAreaChartCommonProperty: LineAndAreaChartCommonProperty = {
    type: 'area',
    plotRuleText: '%total',
    plotRulerule: '%total <= 0',
    stacked: true,
};

export interface EnquiryCountSnapshot {
    enquiryCount: number;
    year: string;
    enquirieCountTillCurrentMonth?: number;
    value?: number;
    percentage?: number;
}

export interface CompareStartingYearFilterValue {
    isEnabled: boolean;
    value: number;
}

export interface ChartRangeSelector {
    startDate: number;
    endDate: number;
}

export interface StudentEnquiries {
    students: Student[];
    yearLevels: YearLevel[];
    stages: ListItem[];
}

const rangeSelectorItemWOAngle = 12;
export const epochDivider = 1000;
export function setChartRangeSelectorPreview(chartData: ZingData, chartRangeSelector: ChartRangeSelector): void {
    chartData.preview.mask.alpha = 0.3;
    chartData.preview.label.fontSize = 10;
    chartData.preview.visible = true;

    chartData.scaleX.step = 'month';
    chartData.scaleX.zoomToValues = [chartRangeSelector.startDate, chartRangeSelector.endDate];
    chartData.scaleX.transform.type = 'date';
    chartData.scaleX.itemsOverlap = false;
    chartData.scaleX.zooming = true;

    chartData.plotarea.adjustLayout = false;

    const visibleSeries = getVisibleSeries(chartData.series, chartRangeSelector);
    chartData.scaleX.item.angle = visibleSeries.length <= rangeSelectorItemWOAngle ? '0' : '-35';
    chartData.preview.margin = visibleSeries.length <= rangeSelectorItemWOAngle ? '300px 50px 0px 50px' : '315px 50px 0px 50px';
}

export function getVisibleSeries(series: ZingSeries[], chartRangeSelector: ChartRangeSelector): seriesValueType[] {
    let visibleSeries: seriesValueType[] = [];
    _.forEach(series, s => {
        visibleSeries = _.filter(s.values, value => {
            return _.inRange(value[0], chartRangeSelector.startDate, chartRangeSelector.endDate);
        });
    });
    return visibleSeries;
}

export function getChartTotalByRange(series: ZingSeries[], chartRangeSelector?: ChartRangeSelector): number {
    return _.sum(
        series.map(s =>
            _.sum(
                _.map(s.values, (v: number[]) => {
                    if (_.first(v) >= chartRangeSelector?.startDate && _.first(v) <= chartRangeSelector?.endDate) {
                        return _.last(v);
                    }
                    if (!chartRangeSelector) {
                        return _.last(v);
                    }
                })
            )
        )
    );
}

/**
 * To setup a total count off each year wise range on a left side of the range selector divider line.
 * @param series
 * @param chartId
 * @param chartType
 */
export function displayYearWiseTotalInRangeSelector(series: ZingSeries[], chartId: string, chartType: CustomZingChartAngularComponent) {
    const defaultLeftMarginFromLine: number = 32;
    const oneDigitLeftMargingFromLine: number = 22;
    const twoDigitLeftMarginFromLine: number = 27;
    const oneDigitRange: number = 10;
    const twoDigitRange: number = 99;
    const enquiryCountSnapshots: EnquiryCountSnapshot[] = getYearWiseEnquiriesCount(series);
    const lastLineId = '-graph-id0-preview-mask-x-right';
    const otherLineId = '-graph-id0-preview-item-';

    _.forEach(enquiryCountSnapshots, (enquiryCountSnapshot, index) => {
        setTimeout(() => {
            let leftMargin: number = 0;
            if (enquiryCountSnapshot.enquiryCount < oneDigitRange) {
                leftMargin = oneDigitLeftMargingFromLine;
            } else if (enquiryCountSnapshot.enquiryCount >= oneDigitRange && enquiryCountSnapshot.enquiryCount <= twoDigitRange) {
                leftMargin = twoDigitLeftMarginFromLine;
            } else {
                leftMargin = defaultLeftMarginFromLine;
            }
            if (enquiryCountSnapshots.length - 1 === index) {
                if (document.getElementById(chartId + lastLineId)) {
                    const position = document.getElementById(chartId + lastLineId).getAttribute('style');
                    chartType.removeobject({
                        type: 'label',
                        id: index + 1,
                    });
                    chartType.addobject({
                        type: 'label',
                        data: {
                            id: index + 1,
                            x:
                                parseInt(position.split('left: ')[1].split('px')[0]) +
                                parseInt(position.split('width: ')[1].split('px')[0]) -
                                leftMargin,
                            y: parseInt(position.split('top: ')[1].split('px')[0]) - 3,
                            text: `(${enquiryCountSnapshot.enquiryCount})`,
                            fontSize: 10,
                            padding: 5,
                        },
                    });
                }
            } else {
                if (document.getElementById(`${chartId}${otherLineId}${index + 1}-path`)) {
                    const position = document
                        .getElementById(`${chartId}${otherLineId}${index + 1}-path`)
                        .getAttribute('d')
                        .split(' ');
                    chartType.removeobject({
                        type: 'label',
                        id: index + 1,
                    });
                    chartType.addobject({
                        type: 'label',
                        data: {
                            id: index + 1,
                            x: parseInt(position[1]) - leftMargin,
                            y: parseInt(position[2]) - 2,
                            text: `(${enquiryCountSnapshot.enquiryCount})`,
                            fontSize: 10,
                            padding: 5,
                        },
                    });
                }
            }
        }, 50);
    });
}

function getYearWiseEnquiriesCount(series: ZingSeries[]): EnquiryCountSnapshot[] {
    const enquiryCountSnapshots: EnquiryCountSnapshot[] = [];
    _.forEach(series, s => {
        _.forEach(s.values, value => {
            const year = new Date(value[0]).getFullYear().toString();
            const existingEnquiryCountSnapshot = _.find(enquiryCountSnapshots, e => e.year === year);
            if (!existingEnquiryCountSnapshot) {
                enquiryCountSnapshots.push({
                    year,
                    enquiryCount: value[1],
                });
            } else {
                existingEnquiryCountSnapshot.enquiryCount += value[1];
            }
        });
    });
    return enquiryCountSnapshots;
}

export function setGridChartColWidth(gridChart: ZingData, chartType: CustomZingChartAngularComponent, maxScaleYValue: number) {
    const addExtraWidthFor3rdLength = 4;
    const addExtraWidthForMoreThen3rdLength = 7;
    const initColWidths =
        maxScaleYValue?.toString().length > 2
            ? maxScaleYValue.toString().length == 3
                ? Constants.GRID_CHART_LEFT_WHITE_SPACE + addExtraWidthFor3rdLength
                : Constants.GRID_CHART_LEFT_WHITE_SPACE +
                  addExtraWidthFor3rdLength +
                  addExtraWidthForMoreThen3rdLength * (maxScaleYValue.toString().length - 3)
            : Constants.GRID_CHART_LEFT_WHITE_SPACE;

    const labelsLength = gridChart.options['col-labels'].length - 1;
    gridChart.options['col-widths'] = [];
    if (chartType) {
        const chartWidth = document.getElementById(chartType.renderObject['id']).offsetWidth;
        gridChart.options['col-widths'].push(getPercentage(initColWidths, chartWidth).toFixed(2) + '%');
        for (let i = 0; i < labelsLength; i++) {
            const width = (chartWidth - initColWidths) / labelsLength;
            gridChart.options['col-widths'].push(getPercentage(width, chartWidth).toFixed(2) + '%');
        }
    }
    return gridChart;
}

function getPercentage(value: number, total: number) {
    return (value / total) * 100 || 0;
}

export type seriesValueType = number | string;

export function getPastAndFutureYears(year: number, durationPastYears: number, durationFutureYears: number): number[] {
    const years: number[] = [];
    const lastPastYear = year - durationPastYears;
    const lastFutureYear = year + durationFutureYears;
    for (let i = lastPastYear; i <= lastFutureYear; i++) {
        years.push(i);
    }
    return years;
}

export const stages = {
    INTEREST: 'INTEREST',
    APPLICANT: 'APPLICANT',
    ENROLED: 'ENROLED',
    DECLINED: 'DECLINED',
    TOTAL_ENQUIRIES: 'TOTALENQUIRIES',
};

export const InProgressDisplayName = 'Started';
export const SubmittedDisplayName = 'Received';

export function addEventAndPersonalTours(
    chartMetaData: ChartMetaData,
    chartId: string,
    chartType: CustomZingChartAngularComponent,
    type: ChartType,
    plotId = 0
) {
    const spaceBetweenShapeX = 0;
    const spaceBetweenShapeY = 20;
    const startPointOfColumn = 1;
    const endPointOfColumn = 4;
    const dividedForAverage = 2;
    const lineAreaChartYPosition = 40;
    const columnChartYPosition = 35;
    const increaseXPosition = 50;
    const lastLineId = `-graph-id0-plotset-plot-${plotId}-node-`;
    const lineAreaOtherLineId = '-marker-circle';
    const columnOtherLineId = '-path';
    _.map(chartMetaData.legends, (l, k) => {
        if (l.isSelected) {
            _.map(chartMetaData.shapes[k], (shape, shapeKey) => {
                setTimeout(() => {
                    let x = 0,
                        y = 0;
                    const element = document.getElementById(`${chartId}${lastLineId}${shapeKey}${lineAreaOtherLineId}`);
                    if (type == ChartType.LineArea && element && element.getAttribute('transform')) {
                        y = lineAreaChartYPosition;
                        if (shapeKey !== 0) {
                            x =
                                parseInt(
                                    document
                                        .getElementById(`${chartId}${lastLineId}${shapeKey}${lineAreaOtherLineId}`)
                                        .getAttribute('transform')
                                        .split('translate(')[1]
                                        .split(',')[0]
                                ) + increaseXPosition;
                            if (shape) {
                                const tempText: string =
                                    l.id === 1
                                        ? _.map(shape, (l: any) => l.object.schoolTour.name).toString()
                                        : `Appointment ${shape.length}`;
                                const text = tempText.replace(/,/g, '<br />');
                                addShape(
                                    l,
                                    text,
                                    shape.length,
                                    k === 0 ? x - spaceBetweenShapeX : x + spaceBetweenShapeX,
                                    k === 0 ? y : y - spaceBetweenShapeY,
                                    chartType
                                );
                            }
                        }
                    }
                    if (type == ChartType.Column && document.getElementById(`${chartId}${lastLineId}${shapeKey}${columnOtherLineId}`)) {
                        x =
                            parseInt(
                                document
                                    .getElementById(`${chartId}${lastLineId}${shapeKey}${columnOtherLineId}`)
                                    .getAttribute('d')
                                    .split(' ')[startPointOfColumn]
                            ) +
                            (parseInt(
                                document
                                    .getElementById(`${chartId}${lastLineId}${shapeKey}${columnOtherLineId}`)
                                    .getAttribute('d')
                                    .split(' ')[endPointOfColumn]
                            ) -
                                parseInt(
                                    document
                                        .getElementById(`${chartId}${lastLineId}${shapeKey}${columnOtherLineId}`)
                                        .getAttribute('d')
                                        .split(' ')[startPointOfColumn]
                                )) /
                                dividedForAverage;
                        y = columnChartYPosition;
                        if (shape) {
                            const tempText: string =
                                l.id === 1 ? _.map(shape, (l: any) => l.object.schoolTour.name).toString() : `Appointment ${shape.length}`;
                            const text = tempText.replace(/,/g, '<br />');
                            addShape(
                                l,
                                text,
                                shape.length,
                                k === 0 ? x - spaceBetweenShapeX : x + spaceBetweenShapeX,
                                k === 0 ? y : y - spaceBetweenShapeY,
                                chartType
                            );
                        }
                    }
                }, 50);
            });
        }
    });
}

function addShape(legend: Legend, text: string, totalShape: number, x: number, y: number, chartType: CustomZingChartAngularComponent) {
    chartType.addobject({
        type: 'shape',
        data: {
            type: legend.id === 1 ? 'triangle' : 'circle',
            angle: legend.id === 1 ? 180 : 0,
            'background-color': '#3c4858',
            size: 3,
            x,
            y,
            cursor: 'hand',
            label: {
                text: totalShape,
                'font-color': '#3c4858',
                'font-size': 10,
                'padding-bottom': legend.id === 1 ? 20 : 20,
            },
            tooltip: {
                text,
            },
        },
    });
}

export function getChartTotalForFunnel(chartRawData: ChartRawData): number {
    const interestIndex = getLegendIndex(chartRawData.legends, stages.TOTAL_ENQUIRIES);
    const applicantIndex = getLegendIndex(chartRawData.legends, stages.APPLICANT);
    const enroledIndex = getLegendIndex(chartRawData.legends, stages.ENROLED);
    const declinedIndex = getLegendIndex(chartRawData.legends, stages.DECLINED);

    const isInterestStageSelected = chartRawData.legends[interestIndex].isSelected;
    const isApplicantStageSelected = chartRawData.legends[applicantIndex].isSelected;
    const isEnroledStageSelected = chartRawData.legends[enroledIndex].isSelected;
    const isDeclineStageSelected = chartRawData.legends[declinedIndex].isSelected;
    let declinedValue = 0;
    if (isDeclineStageSelected) {
        declinedValue = _.sum(_.map(_.map(chartRawData.series, 'values'), declinedIndex));
    }
    if (isInterestStageSelected) {
        return _.sum(_.map(_.map(chartRawData.series, 'values'), interestIndex));
    } else if (isApplicantStageSelected) {
        return _.sum(_.map(_.map(chartRawData.series, 'values'), applicantIndex)) + declinedValue;
    } else if (isEnroledStageSelected) {
        return _.sum(_.map(_.map(chartRawData.series, 'values'), enroledIndex)) + declinedValue;
    } else {
        return declinedValue;
    }
}

export function getFunnelMetricsLegendId(legends: Legend[]): number[] {
    const interestStage = _.find(legends, l => l.name.toUpperCase() === stages.TOTAL_ENQUIRIES);
    const applicantStage = _.find(legends, l => l.name.toUpperCase() === stages.APPLICANT);
    const enroledStage = _.find(legends, l => l.name.toUpperCase() === stages.ENROLED);
    const declineStage = _.find(legends, l => l.name.toUpperCase() === stages.DECLINED);
    if (interestStage.isSelected) {
        return _.map(legends, 'id');
    } else if (applicantStage.isSelected) {
        let selectedIds = [applicantStage.id, enroledStage.id];
        if (declineStage.isSelected) {
            selectedIds.push(declineStage.id);
        }
        return selectedIds;
    } else if (enroledStage.isSelected) {
        let selectedIds = [enroledStage.id];
        if (declineStage.isSelected) {
            selectedIds.push(declineStage.id);
        }
        return selectedIds;
    } else {
        return [declineStage.id];
    }
}

export function getChartTotalForAppsFunnel(chartRawData: ChartRawData, appSeries: ZingSeries[]): number {
    const legends = getApplicationLegends(chartRawData.legends);
    const inProgressIndex = getApplicationLegendIndex(legends, InProgressDisplayName);
    const inReviewIndex = getApplicationLegendIndex(legends, ApplicationStatus.InReview);
    const submittedIndex = getApplicationLegendIndex(legends, ApplicationStatus.Submitted);
    const pendingIndex = getApplicationLegendIndex(legends, ApplicationStatus.Pending);
    const finalizedIndex = getApplicationLegendIndex(legends, ApplicationStatus.Finalized);

    let total = 0;
    _.forEach(appSeries, series => {
        const values = series.values as number[];
        _.forEach(legends, (legend, i) => {
            if (legend.isSelected) {
                switch (i) {
                    case inProgressIndex:
                        total += values[i] - values[submittedIndex];
                        break;
                    case submittedIndex:
                        total += values[i] - values[inReviewIndex];
                        break;
                    case inReviewIndex:
                        total += values[i] - values[pendingIndex];
                        break;
                    case pendingIndex:
                        total += values[i] - values[finalizedIndex];
                        break;
                    case finalizedIndex:
                        total += values[i];
                        break;
                    default:
                        console.log(`unexpected converion ratio input: application stage ${legend.name}`);
                }
            }
        });
    });
    return total;
}

export function getLegendIndex(legend: Array<Legend>, name: string): number {
    return _.findIndex(legend, l => l.name.toUpperCase() == name);
}

export function getApplicationLegends(legend: Legend[]): Legend[] {
    const legends: Legend[] = [];
    const statuses = _.map(ApplicationStatus, s => (s === ApplicationStatus.InProgress ? InProgressDisplayName : s));
    const selectedLegends = _.filter(legend, l => l.isSelected);
    if (selectedLegends?.length) {
        _.forEach(statuses, (item, key) => {
            legends.push({
                id: key + 1,
                name: item,
                isSelected: isApplicationLegendSelected(selectedLegends, item),
            });
        });
    }
    return legends;
}

function isApplicationLegendSelected(selectedLegends: Legend[], applicationLegendName: string): boolean {
    const isInProgress = _.find(selectedLegends, l => l.name === InProgressDisplayName);
    const isSubmitted = _.find(selectedLegends, l => l.name === ApplicationStatus.Submitted);
    const isFinalized = _.find(selectedLegends, l => l.name === ApplicationStatus.Finalized);

    if (isInProgress) {
        return true;
    } else if (isSubmitted) {
        return applicationLegendName !== InProgressDisplayName;
    } else if (isFinalized) {
        return applicationLegendName === ApplicationStatus.Finalized;
    } else {
        return false;
    }
}

export function getApplicationLegendIndex(legend: Array<Legend>, name: string): number {
    return _.findIndex(legend, l => l.name === name);
}

export class GridChartDataBuilder {
    private _gridChartData: ZingData;
    private _chartRawData: ChartRawData;
    private _chartType: CustomZingChartAngularComponent;
    private _maxScaleYValue: number;
    private _hasRatioColumn: boolean;
    private _ratioColumnName: string;
    private _ratioColumnNumeratorIndex: number;
    private _ratioColumnDenominatorIndex: number;
    private _isAggregate: boolean;
    private _chartStoreKey: string;
    private _hasRowTooltip: boolean;
    private _tooltipTranslatedName: string;
    private _compareStartingYearFilterValue: CompareStartingYearFilterValue;
    private _compareStartingYearColumnName: string;

    constructor(
        chartRawData: ChartRawData,
        chartType: CustomZingChartAngularComponent,
        maxScaleYValue: number,
        hasRatioColumn: boolean,
        ratioColumnName: string,
        ratioColumnNumeratorIndex: number,
        ratioColumnDenominatorIndex: number,
        isAggregate: boolean,
        chartStoreKey: string,
        hasRowTooltip: boolean,
        tooltipTranslatedName: string,
        private commaSeparatedNumberPipe: CommaSeparatedNumberPipe,
        compareStartingYearFilterValue: CompareStartingYearFilterValue,
        compareStartingYearColumnName: string
    ) {
        this._chartRawData = chartRawData;
        this._chartType = chartType;
        this._maxScaleYValue = maxScaleYValue;
        this._hasRatioColumn = hasRatioColumn;
        this._ratioColumnName = ratioColumnName;
        this._ratioColumnNumeratorIndex = ratioColumnNumeratorIndex;
        this._ratioColumnDenominatorIndex = ratioColumnDenominatorIndex;
        this._isAggregate = isAggregate;
        this._chartStoreKey = chartStoreKey;
        this._tooltipTranslatedName = tooltipTranslatedName;
        this._hasRowTooltip = hasRowTooltip;
        this._compareStartingYearFilterValue = compareStartingYearFilterValue;
        this._compareStartingYearColumnName = compareStartingYearColumnName;
    }

    get gridChartData(): ZingData {
        return this._gridChartData;
    }

    setGridChartData() {
        this._gridChartData = ChartInitData.getGridChartInitData();
        this._gridChartData.series = [];
        this.addHeaders();
        if (this._compareStartingYearFilterValue?.isEnabled && !this._isAggregate) {
            this.addCompareStartingYearRow();
        }
        this.addRows();
        if (this._hasRatioColumn) {
            this.addRationColumn();
        }
        setGridChartColWidth(this._gridChartData, this._chartType, this._maxScaleYValue);
        this.setStyle();
    }

    private addHeaders(): void {
        this._gridChartData.header = this._isAggregate ? ['Aggregate'] : _.map(this._chartRawData.labels, l => l as string);
        this._gridChartData.header.unshift(''); //To kee a first column as a blank.
        this._gridChartData.options['col-labels'] = this._gridChartData.header;
    }

    private addRows(): void {
        const legends = _.cloneDeep(_.filter(this._chartRawData.legends, l => l.isSelected));

        let nonAggregatedSeries = _.map(this._chartRawData.series, (s: PieSeries) => {
            return _.map(
                _.filter(s.values, (v, k) => this._chartRawData.legends[k].isSelected),
                (v: number, index: number) => {
                    return this.commaSeparatedNumberPipe.transform(v);
                }
            );
        });

        let aggregatedSeries: string[] = [];
        if (this._isAggregate) {
            aggregatedSeries = this.generateAggregateSeries(legends, nonAggregatedSeries);
        }

        // Change Non Aggregate Series for Funnel metrics Chart
        if ([Keys.funnelMetricsByStartingYear, Keys.appsByStartingYear].includes(this._chartStoreKey)) {
            const applicantIndex = getLegendIndex(legends, stages.APPLICANT);

            nonAggregatedSeries = _.map(nonAggregatedSeries, (series: string[]) => {
                return _.map(legends, (legend: Legend, index: number) => {
                    const seriesInNumber = series.map(Number);
                    const denominator =
                        legend.name.toUpperCase() === stages.ENROLED && applicantIndex != -1
                            ? seriesInNumber[applicantIndex]
                            : seriesInNumber[0];
                    if (index != 0) {
                        return `${(seriesInNumber[index] === 0
                            ? 0
                            : parseInt(((seriesInNumber[index] / denominator) * 100).toFixed(0))
                        ).toString()}%`;
                    } else {
                        return `${this.commaSeparatedNumberPipe.transform(seriesInNumber[index])} `;
                    }
                });
            });
        }

        _.forEach(legends, (legend: Legend & { translatedName: string }, key: number) => {
            const dataClass = this._hasRowTooltip
                ? [''].concat(
                      this._isAggregate
                          ? [this.generateClassName(legend.name)]
                          : _.map(nonAggregatedSeries, s => this.generateClassName(legend.name))
                  )
                : [];
            const legendName = this.getLegendName(legend);
            this._gridChartData.series.push({
                values: [legendName].concat(this._isAggregate ? aggregatedSeries[key] : _.map(nonAggregatedSeries, s => s[key])),
                dataClass,
            });
        });
    }

    getLegendName(legend: Legend) {
        switch (this._chartStoreKey) {
            case Keys.funnelMetricsByStartingYear:
                return legend.name.toUpperCase() === stages.INTEREST ? 'Total' : legend.translatedName;
            case Keys.appsByStartingYear:
                return legend.translatedName;
            default:
                return legend.name;
        }
    }

    private generateClassName(legendName: string): string {
        switch (legendName.toUpperCase()) {
            case stages.APPLICANT:
            case stages.ENROLED:
                return 'stageCommonTooltip';
            case stages.DECLINED:
                return 'declinedTooltip';
            case ApplicationStatus.Finalized.toUpperCase():
                return 'finalizedTooltip';
            case ApplicationStatus.InReview.toUpperCase():
            case ApplicationStatus.Pending.toUpperCase():
            case ApplicationStatus.Submitted.toUpperCase():
                return 'stageCommonTooltip';
            default:
                return '';
        }
    }
    private generateAggregateSeries(legends: Legend[], nonAggregatedSeries: string[][]): string[] {
        const aggregatedSeries: number[] = [];
        const applicantIndex = getLegendIndex(legends, stages.APPLICANT);

        _.forEach(legends, (legend: Legend, index: number) => {
            let sum = 0;
            _.forEach(nonAggregatedSeries, (series: string[]) => {
                sum += parseInt(series[index]);
            });
            aggregatedSeries.push(sum);
        });

        if ([Keys.funnelMetricsByStartingYear, Keys.appsByStartingYear].includes(this._chartStoreKey)) {
            return _.map(legends, (legend: Legend, index: number) => {
                const denominator =
                    legend.name.toUpperCase() === stages.ENROLED && applicantIndex != -1
                        ? aggregatedSeries[applicantIndex]
                        : aggregatedSeries[0];
                if (index != 0) {
                    return `${(aggregatedSeries[index] === 0
                        ? 0
                        : parseInt(((aggregatedSeries[index] / denominator) * 100).toFixed(0))
                    ).toString()}%`;
                } else {
                    return `${this.commaSeparatedNumberPipe.transform(aggregatedSeries[index])}`;
                }
            });
        } else {
            return aggregatedSeries.map(String);
        }
    }

    private addCompareStartingYearRow(): void {
        const compareStartingYearSeries = [this._compareStartingYearColumnName];
        const currentYear = moment().year();
        const compareStartingYear = this._compareStartingYearFilterValue.value;
        _.forEach(this._chartRawData.labels, (l: string) => {
            const startingYear = parseInt(l.split(' -')[0]);
            const year = startingYear - (compareStartingYear - currentYear);
            compareStartingYearSeries.push(
                moment()
                    .year(year > currentYear ? currentYear : year)
                    .format('DD-MM-YYYY')
            );
        });
        this._gridChartData.series.push({ values: compareStartingYearSeries });
    }

    private addRationColumn(): void {
        const ratioSeries = [this._ratioColumnName];
        if (this._isAggregate) {
            ratioSeries.push(
                this.calculateRationPercentage(
                    this.getTotalOfSelectedLegend(this._ratioColumnNumeratorIndex),
                    this.getTotalOfSelectedLegend(this._ratioColumnDenominatorIndex)
                )
            );
            this._gridChartData.series.push({ values: ratioSeries });
        } else {
            _.forEach(this._chartRawData.labels, (l, k) => {
                ratioSeries.push(
                    this.calculateRationPercentage(
                        this._chartRawData.series[k].values[this._ratioColumnNumeratorIndex],
                        this._chartRawData.series[k].values[this._ratioColumnDenominatorIndex]
                    )
                );
            });
            this._gridChartData.series.push({ values: ratioSeries });
        }
    }

    private getTotalOfSelectedLegend(index: number): number {
        let total = 0;
        _.forEach(this._chartRawData.labels, (v, k) => {
            total += this._chartRawData.series[k].values[index];
        });
        return total;
    }

    private calculateRationPercentage(numeratorValue: number, denominatorValue: number): string {
        return denominatorValue === 0 ? '-' : (numeratorValue / denominatorValue).toFixed(2);
    }

    private setStyle(): void {
        const headerLeftBorderRadius = {
            borderRadius: '3 0 0 3',
        };
        const headerRightBorderRadius = {
            borderRadius: '0 3 3 0',
        };
        const columnCommonStyle = {
            border: 'none',
            height: 48,
        };
        const backGroundColor = {
            backgroundColor: 'white',
        };
        const fontSize = {
            fontSize: 14,
        };

        this._gridChartData.options.style = {
            '.th_first': {
                ...headerRightBorderRadius,
                ...backGroundColor,
            },
            '.th_1': {
                ...headerLeftBorderRadius,
            },
            '.th_last': {
                ...headerRightBorderRadius,
            },
            '.td': {
                ...columnCommonStyle,
                ...fontSize,
                textAlign: 'center',
            },
            '.td_first': {
                ...columnCommonStyle,
                ...backGroundColor,
                textAlign: 'right',
            },
            '.tr_odd': {
                backgroundColor: getColor('series-brand-table-even-row'),
            },
            '.th': {
                ...fontSize,
                border: 'none',
                fontWeight: 300,
                textAlign: 'center',
                height: 32,
                backgroundColor: getColor('series-brand-primary'),
                borderRight: '0px solid #fff',
                borderLeft: '0px solid #fff',
            },
        };
        if (this._hasRowTooltip) {
            const tooltipStyle: ZingOptionsStyleCustomTooltipStyle = {
                text: '',
                fontSize: 12,
                fontWeight: 300,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
            };
            this._gridChartData.options.style = {
                ...this._gridChartData.options.style,
                '.stageCommonTooltip': {
                    tooltip: {
                        ...tooltipStyle,
                        text: 'Percentage (relative to previous stage)',
                    },
                },
                '.declinedTooltip': {
                    tooltip: {
                        ...tooltipStyle,
                        text: `Percentage of original ${this._tooltipTranslatedName}`,
                    },
                },
                '.finalizedTooltip': {
                    tooltip: {
                        ...tooltipStyle,
                        text: `Percentage of Apps Started`,
                    },
                },
            };
        }
    }
}

/**
 * Prepare json for excel function takes a raw json of zing funnel chart & create an array and
 * convert that array into comma separated string for excel download
 * @param chartEntity
 * @param json
 * @returns
 */
export function prepareJsonRequiredForExcel(chartEntity: ChartEntity, json: string[][]): string[] {
    const rawData: string[][] = getSelectedLegendData(json, chartEntity?.chartRawData?.legends);
    const excelRawData = rawData.filter(e => e.length != 1);
    const excelJson = [];
    excelRawData.forEach((outsideRecord, key) => {
        if (key != 0) {
            const excelEachRowData = {};
            for (const index in outsideRecord) {
                excelEachRowData[`${excelRawData[0][index]} `] = excelRawData[key][index].toString();
            }
            excelJson.push(excelEachRowData);
        }
    });
    return excelJson;
}

/**
 * Prepare json for csv function takes a raw json of zing funnel chart & create an array and
 * convert that array into comma separated string for csv download
 * @param chartEntity
 * @param json
 * @returns
 */
export function prepareJsonRequiredForCSV(chartEntity: ChartEntity, json: string[][]): string[][] {
    const rawData: string[][] = getSelectedLegendData(json, chartEntity?.chartRawData?.legends);
    _.forEach(rawData, (element, key) => {
        element[element.length - 1] += '\n';
        _.forEach(element, (data, dataKey) => {
            rawData[key][dataKey] = data.toString().replace(/\,/g, '');
        });
    });
    return rawData;
}

function getSelectedLegendData(json: string[][], legends: Legend[]): string[][] {
    const csvOrExcelData: string[][] = [];
    _.forEach(json, element => {
        const selectedLegendExcelData = [];
        _.forEach(element, (d, k) => {
            if (k != 0) {
                if (legends[k - 1]?.isSelected || !legends[k - 1]) {
                    selectedLegendExcelData.push(d.toString());
                }
            } else {
                selectedLegendExcelData.push(d.toString());
            }
        });
        csvOrExcelData.push(selectedLegendExcelData);
    });
    return csvOrExcelData;
}

export function doNotHaveAggregateSection(): ChartSection {
    return {
        hasButton: false,
    };
}

export function hasAggregateSection(): ChartSection {
    return {
        hasButton: true,
        isSelected: false,
    };
}

export function hasAggregateSectionWithDefaultAggregate(): ChartSection {
    return {
        hasButton: true,
        isSelected: true,
    };
}
export function doNotHaveSortSection(): ChartSection {
    return {
        hasButton: false,
    };
}

export function doNotHaveSortSectionDefaultSelected(): ChartSection {
    return {
        hasButton: false,
        isSelected: true,
    };
}

export function hasSortSection(): ChartSection {
    return {
        hasButton: true,
        isSelected: false,
    };
}

export function hasSortSectionWithDefaultAggregate(): ChartSection {
    return {
        hasButton: true,
        isSelected: true,
    };
}

export function doNotHaveUnknownSectionDefaultSelected(): ChartSection {
    return {
        hasButton: false,
        isSelected: true,
    };
}
export function doNotHaveUnknownSectionDefaultNotSelected(): ChartSection {
    return {
        hasButton: false,
        isSelected: false,
    };
}

export function hasUnknownSection(): ChartSection {
    return {
        hasButton: true,
        isSelected: false,
    };
}
export function hasUnknownSectionDefaultSelected(): ChartSection {
    return {
        hasButton: true,
        isSelected: true,
    };
}

export function updateChartPositionAndUpdateChartHeight(chartsList: ZingData[]): number {
    const length = _.find(chartsList, c => c.type === 'grid').series.length;
    const oneRowGridChartHeight = 60;
    const fixChartPercentageCount = 75;
    const fixGridChartPercentageCount = 25;
    const oneRowPercentageCount = 5;
    _.forEach(chartsList, chart => {
        if (chart.type === 'grid') {
            chart.x = '0';
            chart.y = fixChartPercentageCount - oneRowPercentageCount * length + '%';
            chart.height = fixGridChartPercentageCount + oneRowPercentageCount * length + '%';
        } else {
            chart.x = '0';
            chart.y = '0';
            chart.height = fixChartPercentageCount - oneRowPercentageCount * length + '%';
        }
    });
    if (length) {
        return Constants.HEIGHT_WITH_GRID_CHART + oneRowGridChartHeight * length;
    }
}

export function getEnquiryObj(student: Student): Enquiries {
    return {
        student,
        month: moment(student.createdAt).format(TempConstants.dateFormats.shortMonth),
        year: moment(student.createdAt).format(TempConstants.dateFormats.year),
        leadSource: student.leadSource ? student.leadSource.name : T.unknown,
        startingYear: student.startingYear,
        stageId: student.studentStatus.stageId,
        schoolIntakeYear: student.schoolIntakeYear ? student.schoolIntakeYear.name : T.unknown,
        campusId: student.campusId,
        leadScore: student.score,
    };
}

export function getAppsEnquiryObj(application: ApplicationSummaryDoc): AppEnquiry {
    const submittedAt = moment(application.submittedAt._seconds * 1000);
    return {
        application,
        submittedAtMonth: submittedAt.format(TempConstants.dateFormats.shortMonth),
        submittedAtYear: submittedAt.format(TempConstants.dateFormats.year),
    };
}

export function createChartViewDetailsObj(isDisabled: boolean, tooltipMessage: string): ChartViewDetails {
    return {
        tooltip: isDisabled ? tooltipMessage : '',
        isDisabled,
    };
}

export class ChartViewDetails {
    isDisabled: boolean;
    tooltip: string;
}

export function getTotalEnquiries(series: PieSeries[]): number {
    return _.sum(_.flatten(_.map(_.map(series, 'values'), value => _.map(value, (v: number[]) => _.last(v)))));
}

export function getFilterQueryParams(filterValues: FilterValue[]): string | undefined {
    if (filterValues?.length > 0) {
        const filterStr = _.map(filterValues, f => {
            isNumberOrStringArray(f.value);
            return `${f.id}=${f.value.join(',')}`;
        });
        return _.join(filterStr, '&');
    }
    return undefined;
}

function isNumberOrStringArray(values: unknown) {
    const isValidValues =
        Array.isArray(values) && (values.every(item => typeof item === 'number') || values.every(item => typeof item === 'string'));
    if (!isValidValues) {
        Utils.showNotification('Unexpected Error: Filter Values have been corrupted.', Colors.danger);
    }
}

export function getFilterValueByKey(filterValues: FilterValue[], key: FilterKey): number[] {
    const value = _(filterValues)
        .filter(f => f.id === key)
        .map('value')
        .flatten()
        .value();
    isNumberOrStringArray(value);
    return value;
}

export class analyticsFilterOption {
    filterId: string;
    value?: number[];
}
