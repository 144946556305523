import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ChartEntity } from './chart.entity';
import { ChartStore } from './chart.store';

@Injectable({ providedIn: 'root' })
export class ChartQuery extends QueryEntity<ChartEntity> {
    chart$ = this.select(state => state);

    constructor(protected store: ChartStore) {
        super(store);
    }

    get(id: string): ChartEntity {
        return this.getEntity(id);
    }

    getChartRawDataObservable(id: string) {
        return this.selectEntity(id, 'chartRawData');
    }

    getAggregateSectionObservable(id: string) {
        return this.selectEntity(id, 'aggregateSection');
    }

    getUnknownSectionObservable(id: string) {
        return this.selectEntity(id, 'unknownSection');
    }

    getFilterObservable(id: string) {
        return this.selectEntity(id, 'filter');
    }

    getPlotObservable(id: string) {
        return this.selectEntity(id, 'hasPlotTotal');
    }

    getLegendObservable(id: string) {
        return this.selectEntity(id, 'hasLegend');
    }

    getChartTypeSelectorObservable(id: string) {
        return this.selectEntity(id, 'chartTypeSelector');
    }

    getATOZSortSectionObservable(id: string) {
        return this.selectEntity(id, 'ATOZSortSection');
    }

    getAscendingSortSectionObservable(id: string) {
        return this.selectEntity(id, 'ascendingSortSection');
    }

    getDescendingSortSectionObservable(id: string) {
        return this.selectEntity(id, 'descendingSortSection');
    }

    getDrillDownObservable(id: string) {
        return this.selectEntity(id, 'drillDownStatus');
    }

    getChartRangeSelectorObservable(id: string) {
        return this.selectEntity(id, 'chartRangeSelector');
    }

    getChartMetaDataObservable(id: string) {
        return this.selectEntity(id, 'chartMetaData');
    }

    getEnquiryCountSnapshotObservable(id: string) {
        return this.selectEntity(id, 'enquiryCountSnapshot');
    }

    getTableObservable(id: string) {
        return this.selectEntity(id, 'hasTable');
    }

    getAppsByStartingYearHiddenSeriesObservable(id: string) {
        return this.selectEntity(id, 'appsStartingYearHiddenSeries');
    }

    getChartViewDetailsObservable(id: string) {
        return this.selectEntity(id, 'chartViewDetails');
    }

    getCompareStartingYearFilterValueObservable(id: string) {
        return this.selectEntity(id, 'compareStartingYearFilterValue');
    }
}
